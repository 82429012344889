<template>
  <v-card max-width="250" class="mx-auto">
    <v-btn
      absolute
      right
      top
      color="warning"
      small
      elevation="0"
      class="black--text button"
      style="z-index: 1; top: 0px; right: 0px"
    >
      {{ $helpers.courseStatus(item.status) }}
    </v-btn>
    <v-card-text>
      <v-img
        v-if="item.image"
        height="150"
        max-height="150"
        :src="`${item.aws}/courses/${item.uuid}/image/${item.image.image}`"
      />
      <v-avatar tile v-else height="150" width="100%" color="grey"></v-avatar>
      <div
        class="body-1 font-weight-bold mt-2"
        :class="dark == 1 ? 'white--text' : 'black--text'"
      >
        {{ item.title.slice(0, 20) }}{{ item.title.length > 20 ? "..." : "" }}
      </div>
      <div
        v-if="!more"
        class="caption"
        v-html="item.description"
        style="height: 20px; overflow: hidden"
      />
      <div v-else class="caption" v-html="item.description" />
      <span style="cursor: pointer" @click="more = !more" class="info--text pt-5">
        Read{{ more ? " short" : " more..." }}
      </span>
    </v-card-text>
    <v-card-actions>
      <v-btn
        block
        color="info"
        :to="{ name: 'Admin Course Details', params: { uuid: item.uuid } }"
      >
        Courses Overview
        <v-icon left>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["item", "dark"],
  data: () => ({
    more: false,
  }),
};
</script>
