<template>
  <section id="courses">
    <div v-if="!loading">
      <div class="text-h5 ml-2 mb-5">Manage Courses</div>
      <v-row no-gutters class="mb-0 pb-0">
        <v-col cols="12" lg="2">
          <search :store="store" :action="action" />
        </v-col>
        <v-col cols="12" lg="2">
          <date :store="store" :action="action" />
        </v-col>
        <v-col cols="12" lg="2">
          <status :store="store" :action="action" />
        </v-col>

        <v-col cols="12" lg="2">
          <count :store="store" :action="action" />
        </v-col>
        <v-col cols="12" lg="2">
          <v-btn @click="fetch" small class="mt-1 mx-2" color="warning" fab>
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xl="9" lg="11">
          <v-expand-transition>
            <!-- <v-card v-if="!compact" rounded="lg" color="transparent">
              <Table v-if="$store.getters['teacher/countGetters'] > 0" :data="courses.data" @delete="remove"/>
              <Table v-else :data="courses" @delete="remove"/>
            </v-card> -->
            <v-row>
              <v-col
                cols="12"
                lg="3"
                v-for="item in courses.data"
                :key="item.id"
              >
                <Card :item="item" :dark="user.dark" />
              </v-col>
            </v-row>
          </v-expand-transition>

          <course-paginate
            v-if="courses.total > 0"
            :store="store"
            collection="courses"
          />
          <div v-else class="text-center body-1 mt-5">
            {{ courses ? "" : "No data found" }}
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-else class="body-1">Loading...</div>
  </section>
</template>

<script>
import Card from "../components/course/Card.vue";
import { mapState } from "vuex";
export default {
  components: {
    Card,
  },
  data: () => ({
    loading: true,
    dialog: false,
    store: "admin",
    action: "getCoursesAction",
    snack: false,
    snackText: "",
    vouche: false,
  }),
  computed: {
    ...mapState("admin", {
      courses: (state) => state.courses,
      user: (state) => state.user,
    }),
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$store.commit("admin/resetPageMutation");
      this.$store
        .dispatch(`${this.store}/${this.action}`, {
          page: 0,
          query: "",
          date: "",
          status: "",
          count: 10,
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>